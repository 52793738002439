<template>

  <form id="form" @submit.prevent="onSubmit">

    <div class="alert alert-danger" role="alert" v-if="Object.keys(errors).length > 0">
      <div class="d-flex gap-2">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="icon icon-tabler icons-tabler-outline icon-tabler-alert-circle">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
            <path d="M12 8v4" />
            <path d="M12 16h.01" />
          </svg>
        </div>
        <div>
          <h4 class="alert-title">Error - please correct the following errors and submit the form again.</h4>
          <div class="text-secondary">
            <ul>
              <li v-for="(error, field) in errors" :key="field">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="alert alert-success" role="alert" v-if="message">
      <div class="d-flex gap-2">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="icon icon-tabler icons-tabler-outline icon-tabler-check">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12l5 5l10 -10" />
          </svg>
        </div>
        <div>
          <h4 class="alert-title">Form Submitted</h4>
          <div class="text-secondary">
            {{ message }}
            <router-link :to=goBackUrl>Go Back</router-link>
          </div>
        </div>
      </div>
    </div>

    <template v-else>

      <div class="row">

        <div class="col-md-6 col-xl-12">
          <div class="mb-3">
            <div id="payment-element"></div>
          </div>
        </div>

        <div class="col-md-12 col-xl-12" v-if="showSetDefaultCheckbox">
          <div class="mb-3">
            <label class="form-check">
              <input class="form-check-input" type="checkbox" name="is_default" v-model="isDefault">
              <span class="form-check-label">Use this payment method as default</span>
            </label>
          </div>
        </div>

        <div class="col-md-12 col-xl-12" v-if="showSubmitButton">
          <div class="mb-3">
            <button v-if="isFormSubmittable" class="btn btn-primary" id="btn-complete">Save</button>
          </div>
        </div>

      </div>

    </template>

  </form>

</template>


<script>
import { loadStripe } from '@stripe/stripe-js';

export default {
  props: {
    showSubmitButton: {
      type: Boolean,
      default: true
    },
    showSetDefaultCheckbox: {
      type: Boolean,
      default: true
    },
    isStandalone: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      
      errors: {},
      message: null,
      
      stripe: null,
      elements: null,
      isDefault: false,
      stripePublishableKey: null,
      clientSecret: null,
      paymentElement: null,
      setupIntent: null,
      isFormSubmittable: false
    };
  },
  computed: {
    goBackUrl() {
      return { name: 'payment-methods', params: this.$dist.rpwiParams() };
    }
  },
  async mounted() {
    try {
      const response = await this.$axios.get(this.$dist.endpoint('payment-methods/publishable-key'));
      this.stripePublishableKey = response.data.publishable_key;

      const setiResponse = await this.$axios.post(this.$dist.endpoint('payment-methods/setup-intent'));
      this.setupIntent = setiResponse.data.setup_intent.id;
      this.clientSecret = setiResponse.data.setup_intent.client_secret;

      this.stripe = await loadStripe(this.stripePublishableKey);
      this.elements = this.stripe.elements({ clientSecret: this.clientSecret });

      this.mountStripeElements();

    } catch (error) {
      console.error('Failed to set up Stripe elements', error);
    }
  },
  methods: {

    mountStripeElements() {
      
      this.paymentElement = this.elements.create('payment', {
        fields: {
          billingDetails : {
           address : {
              country : 'never',
              postalCode: 'never'
           }
          }
        }
      });
      this.paymentElement.mount('#payment-element');
      this.paymentElement.on('change', (event) => {
        if (event.error) {
          this.errors.payment = event.error.message;
        } else {
          delete this.errors.payment;
        }
        this.checkFormValidity();
      });
    },

    checkFormValidity() {
      this.isFormSubmittable = Object.keys(this.errors).length === 0;
    },
    async onSubmit() {
      try {

        const result = await this.stripe.confirmSetup({
          elements: this.elements,
          confirmParams: {
            payment_method_data: {
              billing_details: {
                address: {
                  country: this.$store.state.user.country,
                  postal_code: this.$store.state.user.postal_code
                }
              }
            },
            return_url: window.location.href
          },
          redirect: 'if_required'
        })
        
        if (result.error) {
          console.log(result.error);
          this.handleError(result.error.message);
          return;
        }
        else {
          console.log('confirmSetup: ', result);
        }

        try {
          const registrationResponse = await this.$axios.post(this.$dist.endpoint('payment-methods'), {
            //is_default: this.isDefault,
            is_default: true,
            setup_intent: result.setupIntent.id
          });
          console.log(registrationResponse);
          if (this.isStandalone) {
            this.message = 'Payment method added successfully.';
          }
          else {
            return registrationResponse.data.payment_method.id;
          }
        } catch (error) {
          if (error.response && error.response.data) {
            this.handleError(error.response.data.error);
          }
        }
      } catch (err) {
        console.error(err);
        this.handleError('An unexpected error occurred.');
      }
    },
    handleError(message) {
      this.errors['server'] = message;
    }
  },
  watch: {
    errors: {
      deep: true,
      handler(newVal) {
        this.$emit('errors-changed', { errors: newVal });
      }
    }
  }
};
</script>