<template>
  <div class="page-header d-print-none">
    <div class="container-xl">
      <h2 class="page-title">
        Shipping Addresses
      </h2>
    </div>
  </div>
  <div class="page-body">
    <div class="container-xl">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Edit Shipping Address
          </h4>
        </div>
        <div class="card-body">
          <ShippingAddressForm
            :endpoint="'shipping-addresses/' + $route.params.distributorShippingAddressId"
            http-method="patch"
            :initial-state="initialState"
            :show-set-default-checkbox="false"
            :show-comments-textarea="false"
            v-if="initialState"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShippingAddressForm from './ShippingAddressForm.vue';
export default {
  components: {
    ShippingAddressForm
  },
  data() {
    return {
      initialState: null
    }
  },
  async created() {
    try {
      const response = await this.$axios.get(this.$dist.endpoint('shipping-addresses/' + this.$route.params.distributorShippingAddressId));
      this.initialState = response.data.shipping_address;
    } catch (error) {
      console.error(error);
    }
  }
}
</script>
