<template>
  <div class="page-header d-print-none">
    <div class="container-xl">
      <div class="row g-2 align-items-center">
        <div class="col">
          <h2 class="page-title">
            Hello {{ $store.state.user.first_name }}, welcome to your distributor dashboard.
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="page-body">
    <div class="container-xl">
      <div class="row row-cards">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <p>Elevate Your Lab or Clinic Business with DNA Test Kits.</p>
              <OrderSwitch />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderSwitch from './OrderSwitch.vue';

export default {
  components: {
    OrderSwitch
  }
}
</script>