<template>
  <div class="page-header d-print-none">
    <div class="container-xl">
      <div class="row g-2">
        <div class="col">
          <h2 class="page-title" v-if="isDropship">Step 1: Select item(s) for dropshipping</h2>
          <h2 class="page-title" v-if="isWholesale">Step 1: Select item(s)</h2>
        </div>
      </div>
    </div>
  </div>
    
  <div class="page-body">
    <div class="container-xl">
      <div class="row row-cards">

        <div class="col-12 mb-7" v-if="products.length">
          <template v-for="(group) in groupedProducts" :key="group.category_label">

            <div class="card mb-3">
              
              <div class="card-header">
                <h3 class="card-title">
                  {{ group.category_label }}
                </h3>
              </div>

              <div class="card-body">
              
                <table class="table card-table table-vcenter">
                  <thead>
                    <tr>
                      <th style="width: 10%;">SKU</th>
                      <th style="width: 40%;">Product</th>
                      <th style="width: 40%;">Your Wholesale Price</th>
                      <th style="width: 10%;">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="product in group.items" :key="product.product_offer_id">
                      <tr v-if="product.unit_price && product.case_price && product.case_price.value > 0">
                        <td rowspan="2">
                          {{ product.upc_code }}
                        </td>
                        <td rowspan="2">
                          {{ product.label }}
                        </td>
                        <td>
                          <div v-if="product.unit_price">
                            <div v-if="product.unit_price.label && product.unit_price.discount" class="d-flex flex-column flex-md-row gap-2">
                              <div class="d-flex gap-2">
                                <span class="badge bg-pink" v-if="product.unit_price.discount.label">{{ product.unit_price.discount.label }}</span>
                                <span class="text-decoration-line-through">{{ product.unit_price.formatted }}</span>
                              </div>
                              <div class="d-flex gap-2">
                                <span class="fw-bold text-pink">{{ product.unit_price.discount.formatted }}</span>
                                <span class="fs-5">/ Each</span>
                              </div>
                            </div>
                            <div v-else-if="product.unit_price.label">
                              <span>{{ product.unit_price.formatted }}</span>
                              <span class="fs-5 text-nowrap">/ Each</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <select class="form-select select-quantity" v-if="product.unit_price" v-model="product.unitCount" @change="calculateSubtotal(product)">
                            <option value="0">0</option>
                            <option v-for="n in 50" :key="'unit-' + n" :value="n">{{ n }}</option>
                          </select>
                        </td>
                      </tr>
                      <tr v-if="product.unit_price && product.case_price && product.case_price.value > 0">
                        <td>
                          <div v-if="product.case_price">
                            <div v-if="product.case_price.label && product.case_price.discount" class="d-flex flex-column flex-md-row gap-2">
                              <div class="d-flex gap-2">
                                <span class="badge bg-pink" v-if="product.case_price.discount.label">{{ product.case_price.discount.label }}</span>
                                <span class="text-decoration-line-through">{{ product.case_price.formatted }}</span>
                              </div>
                              <div class="d-flex gap-2">
                                <span class="fw-bold text-pink">{{ product.case_price.discount.formatted }}</span>
                                <span class="fs-5">/ Case of {{ product.case.num_kits }}</span>
                              </div>
                            </div>
                            <div v-else-if="product.case_price.label">
                              <span>{{ product.case_price.formatted }}</span>
                              <span class="fs-5 text-nowrap">/ Case of {{ product.case.num_kits }}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <select class="form-select select-quantity" v-if="product.case_price" v-model="product.caseCount" @change="calculateSubtotal(product)">
                            <option value="0">0</option>
                            <option v-for="n in 50" :key="'case-' + n" :value="n">{{ n }}</option>
                          </select>
                        </td>
                      </tr>
          
                      <tr v-if="product.unit_price && (!product.case_price || product.case_price.value == 0)">
                        <td>
                          {{ product.upc_code }}
                        </td>
                        <td>
                          {{ product.label }}
                        </td>
                        <td>
                          <div v-if="product.unit_price">
                            <div v-if="product.unit_price.label && product.unit_price.discount" class="d-flex flex-column flex-md-row gap-2">
                              <div class="d-flex gap-2">
                                <span class="badge bg-pink" v-if="product.unit_price.discount.label">{{ product.unit_price.discount.label }}</span>
                                <span class="text-decoration-line-through text-nowrap">{{ product.unit_price.formatted }}</span>
                              </div>
                              <div class="d-flex gap-2">
                                <span class="fw-bold text-pink text-nowrap">{{ product.unit_price.discount.formatted }}</span>
                                <span class="fs-5 text-nowrap">/ Each</span>
                              </div>
                            </div>
                            <div v-else-if="product.unit_price.label">
                              <span>{{ product.unit_price.formatted }}</span>
                              <span class="fs-5 text-nowrap">/ Each</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <select class="form-select select-quantity" v-if="product.unit_price" v-model="product.unitCount" @change="calculateSubtotal(product)">
                            <option value="0">0</option>
                            <option v-for="n in 50" :key="'unit-' + n" :value="n">{{ n }}</option>
                          </select>
                        </td>
                      </tr>

                      <tr v-if="(!product.unit_price || product.unit_price.value == 0) && product.case_price">
                        <td>
                          {{ product.upc_code }}
                        </td>
                        <td>
                          {{ product.label }}
                        </td>
                        <td>
                          <div v-if="product.case_price">
                            <div v-if="product.case_price.label && product.case_price.discount" class="d-flex flex-column flex-md-row gap-2">
                              <div class="d-flex gap-2">
                                <span class="badge bg-pink" v-if="product.case_price.discount.label">{{ product.case_price.discount.label }}</span>
                                <span class="text-decoration-line-through">{{ product.case_price.formatted }}</span>
                              </div>
                              <div class="d-flex gap-2">
                                <span class="fw-bold text-pink">{{ product.case_price.discount.formatted }}</span>
                                <span class="fs-5">/ Case of {{ product.case.num_kits }}</span>
                              </div>
                            </div>
                            <div v-else-if="product.case_price.label">
                              <span>{{ product.case_price.formatted }}</span>
                              <span class="fs-5 text-nowrap">/ Case of {{ product.case.num_kits }}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <select class="form-select select-quantity" v-if="product.case_price" v-model="product.caseCount" @change="calculateSubtotal(product)">
                            <option value="0">0</option>
                            <option v-for="n in 50" :key="'case-' + n" :value="n">{{ n }}</option>
                          </select>
                        </td>
                      </tr>

                    </template>
                  </tbody>
                </table>
                
              </div>
            
            </div>

          </template>
          
        </div>

      </div>
    </div>
  </div>

  <div
    v-if="isAnyProductSelected"
    class="offcanvas offcanvas-bottom h-auto show"
    style="z-index: 100 !important"
    tabindex="-1"
    id="offcanvasBottom"
    aria-modal="true"
    role="dialog"
  >
    <div class="offcanvas-body">
      <div class="container me-0">
        <div class="d-flex align-items-center justify-content-end">
          <span class="fw-bold fs-3 me-3">
            Subtotal: {{ formatCurrency(total, region.currency.format) }}
          </span>
          <span>
            <button class="btn btn-primary" @click="onSubmit">Check Out</button>
          </span>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
.select-quantity {
  min-width: 60px;
}
</style>

<script>
export default {
  data() {
    return {
      products: [],
      region: null
    };
  },
  computed: {
    isAnyProductSelected() {
      return this.products.some(p => p.unitCount > 0 || p.caseCount > 0);
    },
    total() {
      return this.products.reduce((acc, p) => acc + p.subtotal, 0);
    },
    isDropship() {
      return this.$route.params.mode === 'dropship';
    },
    isWholesale() {
      return this.$route.params.mode === 'wholesale';
    },
    groupedProducts() {
      const grouped = this.products.reduce((acc, product) => {
        if (!acc[product.category.id]) {
          acc[product.category.id] = {
            category_label: product.category.label,
            items: []
          };
        }
        acc[product.category.id].items.push(product);
        return acc;
      }, {});

      return Object.values(grouped);
    }
  },
  async created() {
    try {
      const savedOrder = JSON.parse(localStorage.getItem('order')) || { units: {}, cases: {} };

      const regionResponse = await this.$axios.get(this.$dist.endpoint('region'));
      this.region = regionResponse.data.region;

      const response = await this.$axios.get(this.$dist.endpoint('products'));
      this.products = response.data.products.map(product => {
        return {
          ...product,
          unitCount: savedOrder.units[product.product_offer_id] || 0,
          caseCount: savedOrder.cases[product.product_offer_id] || 0,
          subtotal: 0
        };
      });
      this.products.map(product => this.calculateSubtotal(product));

    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    calculateSubtotal(product) {
      let unitCount = Number(product.unitCount);
      let caseCount = Number(product.caseCount);

      let unitTotal = (product.unit_price && product.unit_price.value) ? parseFloat(product.unit_price.discount ? product.unit_price.discount.value : product.unit_price.value) * unitCount : 0;
      let caseTotal = (product.case_price && product.case_price.value) ? parseFloat(product.case_price.discount ? product.case_price.discount.value : product.case_price.value) * caseCount : 0;

      product.subtotal = unitTotal + caseTotal;

      this.updateQuantitySaved();
    },
    updateQuantitySaved() {
      let units = {};
      let cases = {};

      this.products.forEach(product => {
        if (product.unitCount > 0) {
          units[product.product_offer_id] = product.unitCount;
        }

        if (product.caseCount > 0) {
          cases[product.product_offer_id] = product.caseCount;
        }
      });

      const order = { units, cases };

      localStorage.setItem('order', JSON.stringify(order));
    },
    formatCurrency(value, formatString) {
      return formatString.replace('%f', new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value));
    },
    onSubmit() {
      this.updateQuantitySaved();
      this.$router.push({ name: 'checkout', params: { mode: this.$route.params.mode, regionPrefix: this.$route.params.regionPrefix, websiteId: this.$route.params.websiteId } });
    }
  }
}
</script>