<template>
 
  <form id="form" @submit.prevent="onSubmit">

    <div class="alert alert-danger" role="alert" v-if="Object.keys(errors).length > 0">
      <div class="d-flex gap-2">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-alert-circle"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M12 8v4" /><path d="M12 16h.01" /></svg>
        </div>
        <div>
          <h4 class="alert-title">Error - please correct the following errors and submit the form again.</h4>
          <div class="text-secondary">
            <ul>
              <li v-for="(error, field) in errors" :key="field">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="alert alert-success" role="alert" v-if="message">
      <div class="d-flex gap-2">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l5 5l10 -10" /></svg>
        </div>
        <div>
          <h4 class="alert-title">Form Submitted</h4>
          <div class="text-secondary">
            {{ message }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 col-xl-12">
      <div class="mb-3">
        <label class="form-label">Business Name</label>
        <div class="form-control-plaintext">{{ businessName }}</div>
      </div>
    </div>

    <div class="col-md-12 col-xl-12">
      <div class="mb-3">
        <label class="form-label">Business Type</label>
        <div class="form-control-plaintext" v-if="businessTypes.length">
          {{ businessTypes.find(option => option.key == businessTypeId).value }}
        </div>
      </div>
    </div>

    <div class="col-md-12 col-xl-12">
      <div class="mb-3">
        <label class="form-label">Website</label>
        <div class="form-control-plaintext">{{ website }}</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-xl-6">
        <div class="mb-3">
          <label class="form-label">First Name</label>
          <input class="form-control" type="text" name="first_name" :class="{ 'is-invalid': errors.first_name }" v-model="firstName">
        </div>
      </div>
      <div class="col-md-6 col-xl-6">
        <div class="mb-3">
          <label class="form-label">First Name</label>
          <input class="form-control" type="text" name="last_name" :class="{ 'is-invalid': errors.last_name }" v-model="lastName">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-xl-6">
        <div class="mb-3">
          <label class="form-label">Contact Phone</label>
          <input class="form-control" type="text" name="contact_phone" :class="{ 'is-invalid': errors.contact_phone }" v-model="contactPhone">
        </div>
      </div>
      <div class="col-md-6 col-xl-6">
        <div class="mb-3">
          <label class="form-label">ext.</label>
          <input class="form-control" type="text" name="contact_phone_ext" :class="{ 'is-invalid': errors.contact_phone_ext }" v-model="contactPhoneExt">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-xl-6">
        <div class="mb-3">
          <label class="form-label">Alt Phone</label>
          <input class="form-control" type="text" name="alt_phone" :class="{ 'is-invalid': errors.alt_phone }" v-model="altPhone">
        </div>
      </div>
      <div class="col-md-6 col-xl-6">
        <div class="mb-3">
          <label class="form-label">ext.</label>
          <input class="form-control" type="text" name="alt_phone_ext" :class="{ 'is-invalid': errors.alt_phone_ext }" v-model="altPhoneExt">
        </div>
      </div>
    </div>

    <div class="col-md-6 col-xl-12">
      <div class="mb-3">
        <button type="submit" class="btn btn-primary ms-auto">Save</button>
      </div>
    </div>

  </form>
  
</template>


<script>
export default {
  props: {
    initialState: Object
  },
  data() {
    return {
      
      message: null,
      errors: {},

      businessTypes: [],

      businessName: null,
      businessTypeId: null,
      website: null,
      firstName: null,
      lastName: null,
      contactPhone: null,
      contactPhoneExt: null,
      altPhone: null,
      altPhoneExt: null,
      
    }
  },
  async created() {
    try {
      
      const businessTypesResponse = await this.$axios.get(this.$dist.endpoint('business-types/select-options'));
      this.businessTypes = businessTypesResponse.data.options;

      if (this.initialState) {
        this.$dist.assignS2C(this.$data, this.initialState);
      }

    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    formData() {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        contact_phone: this.contactPhone,
        contact_phone_ext: this.contactPhoneExt,
        alt_phone: this.altPhone,
        alt_phone_ext: this.altPhoneExt
      };
    },
    async onSubmit() {

      this.message = null;
      this.errors = {};

      try {
        const response = await this.$axios.patch(this.$dist.endpoint('account'), this.formData());
        console.log(response);
        this.message = 'Account info updated successfully.';

      } catch (error) {
        this.errors = error.response.data.errors;
        console.log(error);
      }
    }
  }
}
</script>
