import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import dist from './dist';

const [, regionPrefix, websiteId] = window.location.pathname.split('/');
store.commit('setRegionPrefix', regionPrefix);
store.commit('setWebsiteId', websiteId);

axios.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
)

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      return axios.post(dist.endpoint('auth/refresh', regionPrefix, websiteId), {
        refresh_token: localStorage.getItem('refreshToken')
      }).then(response => {
        
        localStorage.setItem('accessToken', response.data.access_token);
        localStorage.setItem('refreshToken', response.data.refresh_token);

        this.$store.commit('setAccessToken', response.data.access_token);
        this.$store.commit('setUser', response.data.user);
        
        error.config.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
        return axios.request(error.config);
      }).catch(error => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = `/${regionPrefix}/${websiteId}/login?error=session-expired`;
        console.log(error);
      })
    }
    return Promise.reject(error);
  }
)

const app = createApp(App);
app.use(router);
app.use(store);
app.use(dist, { router, store, axios });
app.config.globalProperties.$axios = axios;

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !app.config.globalProperties.$dist.isLoggedIn()) {
    next(`/${app.config.globalProperties.$dist.rpwi()}/login`);
  } else {
    next();
  }
})

async function initApp() {
  const websiteResponse = await axios.get(app.config.globalProperties.$dist.endpoint('website'));
  console.log(websiteResponse.data);
  store.commit('setWebsite', websiteResponse.data.website);

  const accessToken = localStorage.getItem('accessToken');
  if (accessToken && !store.state.user) {
    store.commit('setAccessToken', accessToken);
    await app.config.globalProperties.$axios.get(app.config.globalProperties.$dist.endpoint('auth/user'), {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }).then((response) => {
      store.commit('setUser', response.data);
    }).catch((error) => {
      console.log(error);
    });
  }

  app.mount('#app');
}

initApp();