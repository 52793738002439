import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
  state: {
    accessToken: null,
    user: null,
    regionPrefix: null,
    websiteId: null,
    website: null,
  },
  mutations: {
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setUser(state, user) {
      state.user = user;
    },
    setRegionPrefix(state, regionPrefix) {
      state.regionPrefix = regionPrefix;
    },
    setWebsiteId(state, websiteId) {
      state.websiteId = websiteId;
    },
    setWebsite(state, website) {
      state.website = website;
    }
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
})