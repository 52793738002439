<template>

  <div class="page page-center">
    <div class="container container-tight py-4">
      <div class="card card-md">
        <div class="card-body">

          <div class="text-center mb-2">
            <img :src="$store.state.website.images.logo" v-if="$store.state.website"
              class="navbar-brand navbar-brand-autodark" style="max-width: 365px;" />
          </div>

          <h2 class="h2 text-center mb-4">Distributor Login</h2>

          <div class="alert alert-danger" role="alert" v-if="error">
            <div class="d-flex gap-2">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-alert-circle">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                  <path d="M12 8v4" />
                  <path d="M12 16h.01" />
                </svg>
              </div>
              <div>
                <h4 class="alert-title">Input Error</h4>
                <div class="text-secondary">{{ error }}</div>
              </div>
            </div>
          </div>

          <div class="alert alert-success" role="alert" v-if="message">
            <div class="d-flex gap-2">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-check">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l5 5l10 -10" />
                </svg>
              </div>
              <div>
                <h4 class="alert-title">Form Submitted</h4>
                <div class="text-secondary">
                  {{ message }}
                </div>
              </div>
            </div>
          </div>

          <form novalidate @submit.prevent="login">
            <div class="mb-3">
              <label class="form-label">Email</label>
              <input class="form-control" type="email" id="email" v-model="email" required>
            </div>
            <div class="mb-3">
              <label class="form-label">
                Password
                <span class="form-label-description">
                  <router-link :to="{ name: 'password-request', params: $dist.rpwiParams() }">
                    I forgot password
                  </router-link>
                </span>
              </label>
              <div class="input-group input-group-flat">
                <input class="form-control" type="password" id="password" v-model="password" required>
              </div>
            </div>
            <div id="recaptcha"></div>
            <div class="form-footer">
              <button type="submit" class="btn btn-primary w-100">Log in</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      error: null,
      recaptchaResponse: null
    };
  },
  mounted() {
    this.loadRecaptcha();
  },
  beforeUnmount() {
    if (window.grecaptcha) {
      window.grecaptcha.reset();
    }
  },
  created() {
    if (this.$dist.isLoggedIn()) {
      this.$router.push(`/${this.$dist.rpwi()}/dashboard`);
    }
    switch (this.$route.query['error']) {
      case 'session-expired':
        this.error = 'Your session has expired. Please log in again.';
    }
  },
  methods: {
    loadRecaptcha() {

      const recaptchaElement = document.getElementById('recaptcha');
      if (recaptchaElement) {
        recaptchaElement.innerHTML = '';
      }

      const checkRecaptchaLoad = setInterval(() => {
        if (window.grecaptcha && window.grecaptcha.render) {
          clearInterval(checkRecaptchaLoad);
          window.grecaptcha.render('recaptcha', {
            sitekey: '6LcJnrQUAAAAAPLiJWcbym8FHKO3fPffsqlRRa5H',
            callback: (response) => {
              this.recaptchaResponse = response;
              console.log('ReCAPTCHA response:', response);
            },
          });
        }
      }, 100);
    },
    async login() {
      try {
        const response = await this.$axios.post(this.$dist.endpoint('auth/login'), {
          email: this.email,
          password: this.password,
          recaptcha: this.recaptchaResponse
        });
        localStorage.setItem('accessToken', response.data.access_token);
        localStorage.setItem('refreshToken', response.data.refresh_token);
        this.$store.commit('setAccessToken', response.data.access_token);
        this.$store.commit('setUser', response.data.user);
        this.$router.push(`/${this.$dist.rpwi()}/dashboard`);
      } catch (error) {
        console.log('error', error);
        this.error = error.response.data.errors.login;
        if (window.grecaptcha) {
          window.grecaptcha.reset();
        }
      }
    }
  }
};
</script>