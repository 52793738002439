<template>

  <div class="page-header d-print-none">
    <div class="container-xl">
      <div class="row g-2 align-items-center">
        <div class="col">
          <h2 class="page-title">
            Order History
          </h2>
        </div>
      </div>
    </div>
  </div>

  <div class="page-body">
    <div class="container-xl">
      <div class="row row-cards">
        <div class="col-12">
          <div class="card">
            <div class="card-body" v-if="orders.length">
              <table class="table card-table table-vcenter">
                <thead>
                  <tr>
                    <th>Order #</th>
                    <th>Date Ordered</th>
                    <th>Items</th>
                    <th>Total</th>
                    <th>Shipping Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order in orders" :key="order.id">
                    <td>
                      <div class="mb-3">
                        <router-link :to="{ name: 'orders-invoice', params: { orderId: order.id } }">
                          {{ order.full_id }}
                        </router-link>
                      </div>
                      <div>
                        <span v-if="order.is_wholesale" class="badge bg-green">Wholesale</span>
                        <span v-if="order.is_dropshipping" class="badge bg-red">Dropship</span>
                      </div>
                    </td>
                    <td>{{ order.ordered_at_f }}</td>
                    <td>
                      <ul class="mb-0">
                        <li v-for="item in order.items" :key="item.id">
                          {{ item.product }} ({{ item.price_f }} x {{ item.quantity }})
                        </li>
                      </ul>
                    </td>
                    <td>{{ order.total_f }}</td>
                    <td>
                      <div v-for="address in order.shipping_addresses" :key="address.id">
                        <p v-html="address.html" />
                        <p v-if="address.tracking_no">
                          <strong>Tracking No:</strong><br />
                          <a target="_blank" :href="address.tracking_url">{{ address.tracking_no }}</a>
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-body" v-else>
              <p>No order record found.</p>
              <OrderSwitch />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>

import OrderSwitch from './OrderSwitch.vue';

export default {
  components: {
    OrderSwitch
  },
  data() {
    return {
      orders: [],
    }
  },
  async created() {
    try {
      const response = await this.$axios.get(this.$dist.endpoint('orders'))
      this.orders = response.data.orders
      console.log(response.data.orders);
    } catch (error) {
      console.error(error)
    }
  }
}
</script>