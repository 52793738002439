<template>
  <div class="page-header d-print-none">
    <div class="container-xl">
      <h2 class="page-title">
        Payment Methods
      </h2>
    </div>
  </div>

  <div class="page-body">
    <div class="container-xl">
      <div class="row row-cards">
        <div class="col-12">

          <div class="card" v-if="payment_methods.length">

            <div class="card-header">
              <router-link :to="{ name: 'payment-methods-add' }" href="#">
                Add New Payment Method
              </router-link>
            </div>

            <div class="card-body">
              <table class="table card-table table-vcenter">
                <thead>
                  <tr>
                    <th>Brand</th>
                    <th>Card Number</th>
                    <th>Expiry</th>
                    <th>Default</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="payment_method in payment_methods" :key="payment_method.id">
                    <td>{{ payment_method.brand_f }}</td>
                    <td>{{ payment_method.last4_f }}</td>
                    <td>{{ payment_method.exp_f }}</td>
                    <td>
                      <span v-if="payment_method.is_default" class="badge bg-green">Default</span>
                      <span v-else>
                        <a @click="setDefault(payment_method.id)" href="#">
                          Set as Default
                        </a>
                      </span>
                    </td>
                    <td>
                      <div class="d-flex gap-4">
                        <a v-if="!payment_method.is_default" @click="deletePaymentMethod(payment_method.id)"
                          class="text-danger">
                          Delete
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card" v-else>
            <div class="card-body">
              No payment method found.
              <router-link :to="{ name: 'payment-methods-add' }" href="#">
                Add New Payment Method
              </router-link>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="modal modal-blur fade" id="modal-confirm-delete" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="modal-status bg-danger"></div>
        <div class="modal-body text-center py-4">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-danger icon-lg" width="24" height="24"
            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z" />
            <path d="M12 9v4" />
            <path d="M12 17h.01" />
          </svg>
          <h3>Warning</h3>
          <div class="text-muted">Are you sure you want to delete this payment method permanently?</div>
        </div>
        <div class="modal-footer">
          <div class="w-100">
            <div class="row">
              <div class="col"><a href="#" class="btn w-100" data-bs-dismiss="modal">No, Cancel</a></div>
              <div class="col">
                <a @click="confirmDelete" href="#" class="btn btn-danger w-100" data-bs-dismiss="modal">
                  Yes, Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
</style>


<script>

export default {
  data() {
    return {
      payment_methods: [],
      deleteId: null
    }
  },
  async created() {
    try {
      const response = await this.$axios.get(this.$dist.endpoint('payment-methods'))
      this.payment_methods = response.data.payment_methods
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    deletePaymentMethod(id) {
      this.deleteId = id;
    },
    async confirmDelete() {
      try {
        await this.$axios.delete(this.$dist.endpoint('payment-methods/' + this.deleteId))
        this.payment_methods = this.payment_methods.filter(payment_method => payment_method.id !== this.deleteId)
        this.closeModal();
      } catch (error) {
        console.error(error)
      }
    },
    closeModal() {
      this.deleteId = null;
    },
    async setDefault(id) {
      try {
        await this.$axios.patch(this.$dist.endpoint('payment-methods/' + id + '/set-default'))
        this.payment_methods = this.payment_methods.map(payment_method => {
          payment_method.is_default = payment_method.id === id
          return payment_method
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>