<template>
  <div class="page-header d-print-none">
    <div class="container-xl">
      <h2 class="page-title">
        Shipping Addresses
      </h2>
    </div>
  </div>
  
  <div class="page-body">
    <div class="container-xl">
      <div class="row row-cards">
        <div class="col-12">
          <div class="card">

            <div class="card-header">
              <router-link
                v-if="shipping_addresses.length" :to="{ name: 'shipping-addresses-add' }"
                href="#"
              >
                  Add New Shipping Address
              </router-link>
            </div>

            <div class="card-body" v-if="shipping_addresses.length">
              <table class="table card-table table-vcenter">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Phone</th>
                    <th>Default</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="shipping_address in shipping_addresses" :key="shipping_address.id">
                    <td>{{ shipping_address.first_name }} {{ shipping_address.last_name }}</td>
                    <td class="fs-5" v-html="shipping_address.html"></td>
                    <td>{{ shipping_address.phone }}</td>
                    <td>
                      <span v-if="shipping_address.is_default" class="badge bg-green">Default</span>
                      <span v-else>
                        <a
                          @click="setDefault(shipping_address.id)"
                          href="#"
                        >
                          Set as Default
                      </a>
                      </span>
                    </td>
                    <td>
                      <div class="d-flex gap-4">
                        <router-link
                          :to="{ name: 'shipping-addresses-edit', params: $dist.rpwiParams({ distributorShippingAddressId: shipping_address.id }) }"
                          href="#"
                        >
                          Edit
                        </router-link>
                        <a
                          v-if="!shipping_address.is_default"
                          @click="deleteAddress(shipping_address.id)"
                          class="text-danger"
                          href="#"
                          data-bs-toggle="modal" data-bs-target="#modal-confirm-delete"
                        >
                          Delete
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal modal-blur fade" id="modal-confirm-delete" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="modal-status bg-danger"></div>
        <div class="modal-body text-center py-4">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-danger icon-lg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z" /><path d="M12 9v4" /><path d="M12 17h.01" /></svg>
          <h3>Warning</h3>
          <div class="text-muted">Are you sure you want to delete this shipping address permanently?</div>
        </div>
        <div class="modal-footer">
          <div class="w-100">
            <div class="row">
              <div class="col"><a href="#" class="btn w-100" data-bs-dismiss="modal">No, Cancel</a></div>
              <div class="col">
                <a
                  @click="confirmDelete"
                  href="#"
                  class="btn btn-danger w-100"
                  data-bs-dismiss="modal"
                >
                  Yes, Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>

export default {
  data() {
    return {
      shipping_addresses: [],
      deleteId: null
    }
  },
  async created() {
    try {
      const response = await this.$axios.get(this.$dist.endpoint('shipping-addresses'))
      this.shipping_addresses = response.data.shipping_addresses
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    deleteAddress(id) {
      this.deleteId = id;
    },
    async confirmDelete() {
      try {
        await this.$axios.delete(this.$dist.endpoint('shipping-addresses/' + this.deleteId))
        this.shipping_addresses = this.shipping_addresses.filter(shipping_address => shipping_address.id !== this.deleteId)
        this.closeModal();
      } catch (error) {
        console.error(error)
      }
    },
    closeModal() {
      this.deleteId = null;
    },
    async setDefault(id) {
      try {
        await this.$axios.patch(this.$dist.endpoint('shipping-addresses/' + id + '/set-default'))
        this.shipping_addresses = this.shipping_addresses.map(shipping_address => {
          shipping_address.is_default = shipping_address.id === id
          return shipping_address
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>