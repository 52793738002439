<template>
  
  <form id="form" @submit.prevent="onSubmit" @change="emitChangeEvent">
  
    <div class="alert alert-danger" role="alert" v-if="Object.keys(errors).length > 0">
      <div class="d-flex gap-2">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-alert-circle"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M12 8v4" /><path d="M12 16h.01" /></svg>
        </div>
        <div>
          <h4 class="alert-title">Error - please correct the following errors and submit the form again.</h4>
          <div class="text-secondary">
            <ul>
              <li v-for="(error, field) in errors" :key="field">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="alert alert-success" role="alert" v-if="message">
      <div class="d-flex gap-2">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l5 5l10 -10" /></svg>
        </div>
        <div>
          <h4 class="alert-title">Form Submitted</h4>
          <div class="text-secondary">
            {{ message }}
            <router-link :to=goBackUrl>Go Back</router-link>
          </div>
        </div>
      </div>
    </div>

    <template v-else>

      <div class="row">
        <div class="col-md-6 col-xl-6">
          <div class="mb-3">
            <label class="form-label">* First Name</label>
            <input class="form-control" type="text" name="first_name" :class="{ 'is-invalid': errors.first_name }" v-model="firstName">
          </div>
        </div>
        <div class="col-md-6 col-xl-6">
          <div class="mb-3">
            <label class="form-label">* Last Name</label>
            <input class="form-control" type="text" name="last_name" :class="{ 'is-invalid': errors.last_name }" v-model="lastName">
          </div>
        </div>
      </div>

      <div class="col-md-12 col-xl-12">
        <div class="mb-3">
          <label class="form-label">Company Name</label>
          <input class="form-control" type="text" name="company_name" :class="{ 'is-invalid': errors.company_name }" v-model="companyName">
        </div>
      </div>

      <div class="col-md-12 col-xl-12">
        <div class="mb-3">
          <label class="form-label">* Address 1</label>
          <input class="form-control" type="text" name="address_1" :class="{ 'is-invalid': errors.address_1 }" v-model="address1">
        </div>
      </div>

      <div class="col-md-12 col-xl-12">
        <div class="mb-3">
          <label class="form-label">Address 2</label>
          <input class="form-control" type="text" name="address_2" :class="{ 'is-invalid': errors.address_2 }" v-model="address2">
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-xl-6">
          <div class="mb-3">
            <label class="form-label">* Country</label>
            <select
              :class="{ 'is-invalid': errors.country_id }"
              v-model="countryId"
              @change="fetchStates"
              class="form-select"
            >
              <option v-for="country in countries" :key="country.key" :value="country.key">
                {{ country.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-xl-6">
          <div class="mb-3">
            <label class="form-label">State</label>
            <template v-if="stateType === 'select'">
              <select
                :class="{ 'is-invalid': errors.state_id }"
                v-model="stateId"
                name="state_id"
                class="form-select"
              >
                <option v-for="state in states" :key="state.key" :value="state.key">
                  {{ state.value }}
                </option>
              </select>
            </template>
            <template v-else>
              <input
                class="form-control"
                type="text"
                name="state"
                :class="{ 'is-invalid': errors.state }"
                v-model="state"
              >
            </template>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-xl-6">
          <div class="mb-3">
            <label class="form-label">City</label>
            <input class="form-control" type="text" name="city" :class="{ 'is-invalid': errors.city }" v-model="city">
          </div>
        </div>
        <div class="col-md-6 col-xl-6">
          <div class="mb-3">
            <label class="form-label">Zip Code</label>
            <input class="form-control" type="text" name="zip_code" :class="{ 'is-invalid': errors.zip_code }" v-model="zipCode">
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-6">
        <div class="mb-3">
          <label class="form-label">Phone</label>
          <input class="form-control" type="text" name="phone" :class="{ 'is-invalid': errors.phone }" v-model="phone">
        </div>
      </div>

      <div class="col-md-12 col-xl-12" v-if="showCommentsTextarea">
        <div class="mb-3">
          <label class="form-label">Comments</label>
          <textarea class="form-control" name="comments" :class="{ 'is-invalid': errors.comments }" v-model="comments"></textarea>
        </div>
      </div>

      <div class="col-md-6 col-xl-6" v-if="showSetDefaultCheckbox">
        <div class="mb-3">
          <label class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              name="is_default"
              v-model="isDefault"
            >
            <span class="form-check-label">Use this shipping address as default</span>
          </label>
        </div>
      </div>

      <div class="col-md-6 col-xl-12" v-if="showSubmitButton">
        <div class="mb-3">
          <button type="submit" class="btn btn-primary ms-auto">Save</button>
        </div>
      </div>

    </template>

  </form>

</template>


<script>

export default {
  props: {
    initialState: Object,
    endpoint: String,
    httpMethod: String,
    showSubmitButton: {
      type: Boolean,
      default: true
    },
    showSetDefaultCheckbox: {
      type: Boolean,
      default: true
    },
    showCommentsTextarea: {
      type: Boolean,
      default: true
    },
    externalErrors: Object
  },
  data() {
    return {
      countries: [],
      states: [],
      stateType: null,

      message: null,
      errors: {},

      firstName: null,
      lastName: null,
      companyName: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      stateId: null,
      countryId: null,
      zipCode: null,
      phone: null,
      comments: null,
      isDefault: false
    }
  },
  computed: {
    goBackUrl() {
      return { name: 'shipping-addresses', params: this.$dist.rpwiParams() };
    }
  },
  async created() {
    try {

      const response = await this.$axios.get(this.$dist.endpoint('countries/select-options'))
      this.countries = response.data.options

      if (this.initialState) {
        this.$dist.assignS2C(this.$data, this.initialState);
      }

    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    formData() {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        company_name: this.companyName,
        address_1: this.address1,
        address_2: this.address2,
        city: this.city,
        state: this.state,
        state_id: this.stateId,
        country_id: this.countryId,
        zip_code: this.zipCode,
        phone: this.phone,
        comments: this.comments,
        is_default: this.isDefault
      };
    },
    async fetchStates() {
      if (this.countryId) {
        try {
          const response = await this.$axios.get(this.$dist.endpoint('states/select-options'), {
            params: { country_id: this.countryId }
          });
          this.states = response.data.options;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async onSubmit() {

      this.message = null;
      this.errors = {};

      try {
        const response = await this.$axios[this.httpMethod](this.$dist.endpoint(this.endpoint), this.formData());
        this.message = 'Shipping address has been ' + (this.$route.params.distributorShippingAddressId ? 'modified' : 'added') + ' successfully.';
        console.log(response.data);
      } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          this.errors = error.response.data.errors;
        }
      }
    },
    emitChangeEvent(event) {
      this.$emit('form-change', {
        event: event,
        formData: this.formData()
      });
    }
  },
  watch: {
    countryId: function (newVal) {
      const selectedCountry = this.countries.find(country => country.key === newVal);
      if (selectedCountry) {
        this.stateType = selectedCountry.state;
        if (this.stateType === 'select') {
          this.fetchStates();
        }
      } else {
        this.stateType = null;
      }
    },
    externalErrors: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.errors = newVal || {};
      },
    },
  }
}
</script>