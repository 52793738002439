<template>
  <div class="page">
    <SideNav v-if="$dist.isLoggedIn()" />
    <GlobalNav v-if="$dist.isLoggedIn()" />
    <div class="page-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
import GlobalNav from '@/components/GlobalNav.vue'
import SideNav from '@/components/SideNav.vue'

export default {
  name: 'App',
  components: {
    GlobalNav,
    SideNav
  },
  mounted() {
    document.documentElement.style.setProperty('--rgb-button', this.$store.state.website.rgb.button);
    document.documentElement.style.setProperty('--rgb-button-hover', this.$store.state.website.rgb.button_hover);

    const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = 'https://' + this.$store.state.website.fqdn.primary + '/favicon.ico';
    document.getElementsByTagName('head')[0].appendChild(link);

  }
}
</script>

<style>

</style>
