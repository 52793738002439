export default {
  install: (app, { router, store, axios }) => {
    app.config.globalProperties.$dist = {
      
      apiDomain: 'https://www.ssl-status.com',

      rpwi: function () {
        const regionPrefix = store.state.regionPrefix;
        const websiteId = store.state.websiteId;
        return `${regionPrefix}/${websiteId}`;
      },

      rpwiParams: function (otherParams = {}) {
        return Object.assign({
          regionPrefix: store.state.regionPrefix,
          websiteId: store.state.websiteId
        }, otherParams);
      },
      
      endpoint: function (name) {
        return `${this.apiDomain}/v3/dist/${this.rpwi()}/${name}`;
      },

      isLoggedIn: function () {
        return !!store.state.user && store.state.user.region_prefix === store.state.regionPrefix;
      },

      user: function () {
        return store.state.user;
      },

      camelCase: function (str) {
        return str.replace(/_([a-z])/g, function (g) {
          return g[1].toUpperCase();
        }).replace(/_/g, '');
      },

      snakeCase(str) {
        return str.replace(/([A-Z0-9])/g, function (match) {
          return "_" + match.toLowerCase();
        }).replace(/^_/, '');
      },

      assignS2C: function (toObj, fromObj, createNewProps = false) {
        for (let key in fromObj) {
          let toKey = this.camelCase(key);
          if (createNewProps || Object.prototype.hasOwnProperty.call(toObj, toKey)) {
            toObj[toKey] = fromObj[key];
          }
        }
        return toObj;
      },

      assignC2S: function (toObj, fromObj, createNewProps = false) {
        for (let key in fromObj) {
          let toKey = this.snakeCase(key);
          if (createNewProps || Object.prototype.hasOwnProperty.call(toObj, toKey)) {
            toObj[toKey] = fromObj[key];
          }
        }
        return toObj;
      },

      logout: async function () {
        const response = await axios.post(this.endpoint('auth/revoke'));
        console.log(response);
        router.push({ path: `/${this.rpwi()}/login`, query: { message: 'logout' } }).then(() => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          store.commit('setAccessToken', null);
          store.commit('setUser', null);
          localStorage.removeItem('order');
        });
      },

    }

  }
}
