<template>
  <div class="page-header d-print-none">
    <div class="container-xl">
      <h2 class="page-title">
        Payment Methods
      </h2>
    </div>
  </div>
  <div class="page-body">
    <div class="container-xl">
      <div class="row row-cards">
        
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">
                Add New Payment Method
              </h4>
            </div>
            <div class="card-body">
              <PaymentMethodForm endpoint="shipping-addresses" http-method="post" />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PaymentMethodForm from './PaymentMethodForm.vue';
export default {
  components: {
    PaymentMethodForm
  }
}
</script>

