<template>

  <div class="page page-center">
    <div class="container container-tight py-4">
      <form class="card card-md" autocomplete="off" novalidate @submit.prevent="onSubmit">
        <div class="card-body">
          <div class="text-center mb-2">
            <img :src="$store.state.website.images.logo" v-if="$store.state.website"
              class="navbar-brand navbar-brand-autodark" style="max-width: 365px;" />
          </div>
          <h2 class="card-title text-center mb-4">Password Reset</h2>
          <p class="text-muted mb-4">Enter your new password.</p>

          <div class="alert alert-danger" role="alert" v-if="Object.keys(errors).length > 0">
            <div class="d-flex gap-2">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-alert-circle">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                  <path d="M12 8v4" />
                  <path d="M12 16h.01" />
                </svg>
              </div>
              <div>
                <h4 class="alert-title">Input Error</h4>
                <div class="text-secondary">
                  <ul>
                    <li v-for="(error, field) in errors" :key="field">
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="alert alert-success" role="alert" v-if="message">
            <div class="d-flex gap-2">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-check">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l5 5l10 -10" />
                </svg>
              </div>
              <div>
                <h4 class="alert-title">Form Submitted</h4>
                <div class="text-secondary">
                  Password reset successful.
                  <router-link :to="{ name: 'login', params: $dist.rpwiParams() }">
                    Please login.
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <template v-else>

            <div class="mb-3">
              <label class="form-label">New Password</label>
              <input class="form-control" type="password" name="password" v-model="password" required>
            </div>

            <div class="mb-3">
              <label class="form-label">Confirm Password</label>
              <input class="form-control" type="password" name="re_password" v-model="rePassword" required>
            </div>

            <div class="form-footer">
              <button type="submit" class="btn btn-primary w-100">
                Reset Passowrd
              </button>
            </div>

          </template>

        </div>
      </form>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      email: '',
      errors: {},
      message: false, // need a lnik back to login form, so keep it bool.
      
      password: null,
      rePassword: null
    };
  },
  created() {
    if (this.$dist.isLoggedIn()) {
      this.$router.push(`/${this.$dist.rpwi()}/dashboard`);
    }
  },
  methods: {
    async onSubmit() {

      this.errors = {};
      this.message = null;

      try {
        const response = await this.$axios.post(this.$dist.endpoint('passwords/reset'), {
          password: this.password,
          re_password: this.rePassword,
          code: this.$route.params.code
        });
        console.log(response);
        //this.message = 'Password reset successful. Please login.';
        this.message = true;
      } catch (error) {
        console.log('error', error.response.data);
        this.errors = error.response.data.errors;
      }
    }
  }
};
</script>