<template>

  <form id="form" @submit.prevent="onSubmit">

    <div class="alert alert-danger" role="alert" v-if="Object.keys(errors).length > 0">
      <div class="d-flex gap-2">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-alert-circle"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M12 8v4" /><path d="M12 16h.01" /></svg>
        </div>
        <div>
          <h4 class="alert-title">Error - please correct the following errors and submit the form again.</h4>
          <div class="text-secondary">
            <ul>
              <li v-for="(error, field) in errors" :key="field">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="alert alert-success" role="alert" v-if="message">
      <div class="d-flex gap-2">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l5 5l10 -10" /></svg>
        </div>
        <div>
          <h4 class="alert-title">Form Submitted</h4>
          <div class="text-secondary">
            {{ message }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-xl-6">
      <div class="mb-3">
        <label class="form-label">New Password</label>
        <input class="form-control" type="password" name="new_password" :class="{ 'is-invalid': errors.new_password }" v-model="newPassword">
      </div>
    </div>

    <div class="col-md-6 col-xl-6">
      <div class="mb-3">
        <label class="form-label">Confirm Password</label>
        <input class="form-control" type="password" name="confirm_password" :class="{ 'is-invalid': errors.confirm_password }" v-model="confirmPassword">
      </div>
    </div>

    <div class="col-md-6 col-xl-6">
      <div class="mb-3">
        <label class="form-label">Current Password</label>
        <input class="form-control" type="password" name="password" :class="{ 'is-invalid': errors.password }" v-model="password">
      </div>
    </div>

    <div class="col-md-6 col-xl-6">
      <div class="mb-3">
        <button type="submit" class="btn btn-primary ms-auto">Save</button>
      </div>
    </div>

  </form>

</template>

<script>
export default {
  data() {
    return {

      message: null,
      errors: {},

      newPassword: null,
      confirmPassword: null,
      password: null

    }
  },
  methods: {
    formData() {
      return {
        new_password: this.newPassword,
        confirm_password: this.confirmPassword,
        password: this.password
      };
    },
    async onSubmit() {

      this.message = null;
      this.errors = {};

      try {
        const response = await this.$axios.patch(this.$dist.endpoint('account/password'), this.formData());
        console.log(response);
        this.message = 'Account password updated successfully.';

      } catch (error) {
        this.errors = error.response.data.errors;
        console.log(error);
      }
    }
  }
}
</script>
