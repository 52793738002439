<template>

  <div class="page page-center">
    <div class="container container-tight py-4">
      <form class="card card-md" action="./" method="get" autocomplete="off" novalidate @submit.prevent="onSubmit">
        <div class="card-body">

          <div class="text-center mb-2">
            <img :src="$store.state.website.images.logo" v-if="$store.state.website"
              class="navbar-brand navbar-brand-autodark" style="max-width: 365px;" />
          </div>
          
          <h2 class="card-title text-center mb-4">Password Reset</h2>
          <p class="text-muted mb-4">Enter your email address to request password reset.</p>

          <div class="alert alert-danger" role="alert" v-if="error">
            <div class="d-flex gap-2">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-alert-circle">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                  <path d="M12 8v4" />
                  <path d="M12 16h.01" />
                </svg>
              </div>
              <div>
                <h4 class="alert-title">Input Error</h4>
                <div class="text-secondary">{{ error }}</div>
              </div>
            </div>
          </div>

          <div class="alert alert-success" role="alert" v-if="message">
            <div class="d-flex gap-2">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-check">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l5 5l10 -10" />
                </svg>
              </div>
              <div>
                <h4 class="alert-title">Form Submitted</h4>
                <div class="text-secondary">
                  {{ message }}
                </div>
              </div>
            </div>
          </div>

          <template v-else>

            <div class="mb-3">
              <label class="form-label">Email</label>
              <input class="form-control" type="email" id="email" v-model="email" required>
            </div>

            <div id="recaptcha" class=""></div>
            <div class="form-footer">
              <button type="submit" class="btn btn-primary w-100">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                  stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                  <path d="M3 7l9 6l9 -6" />
                </svg>
                Request Passowrd Reset
              </button>
            </div>

          </template>

        </div>
      </form>
      <div class="text-center text-muted mt-3">
        <router-link :to="{ name: 'login', params: $dist.rpwiParams() }">
          Go back to login form
        </router-link>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      email: '',
      error: null,
      message: null,
      recaptchaResponse: null
    };
  },
  mounted() {
    this.loadRecaptcha();
  },
  beforeUnmount() {
    if (window.grecaptcha) {
      window.grecaptcha.reset();
    }
  },
  created() {
    if (this.$dist.isLoggedIn()) {
      this.$router.push(`/${this.$dist.rpwi()}/dashboard`);
    }
  },
  methods: {
    loadRecaptcha() {

      const recaptchaElement = document.getElementById('recaptcha');
      if (recaptchaElement) {
        recaptchaElement.innerHTML = '';
      }
      
      const checkRecaptchaLoad = setInterval(() => {
        if (window.grecaptcha && window.grecaptcha.render) {
          clearInterval(checkRecaptchaLoad);
          window.grecaptcha.render('recaptcha', {
            sitekey: '6LcJnrQUAAAAAPLiJWcbym8FHKO3fPffsqlRRa5H',
            callback: (response) => {
              this.recaptchaResponse = response;
              console.log('ReCAPTCHA response:', response);
            },
          });
        }
      }, 100);
    },
    async onSubmit() {

      this.error = null;
      this.message = null;

      try {
        const response = await this.$axios.post(this.$dist.endpoint('passwords/request'), {
          email: this.email,
          recaptcha: this.recaptchaResponse,
          region_id: this.$route.params.regionPrefix,
          website_id: this.$route.params.websiteId
        });
        console.log(response);
        this.message = 'Password reset request has been sent. Please check your email.';
      } catch (error) {
        console.log('error', error.response.data);
        this.error = error.response.data.error;
        if (window.grecaptcha) {
          window.grecaptcha.reset();
        }
      }
    }
  }
};
</script>