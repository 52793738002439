<template>

  <div class="page-header d-print-none">
    <div class="container-xl">
      <div class="row g-2 align-items-center">
        <div class="col">
          <h2 class="page-title">
            Invoice
          </h2>
        </div>
      </div>
    </div>
  </div>

  <div class="page-body">
    <div class="container-xl">
      <div class="row row-cards">
        <div class="col-12">
          <div class="card">

            <div class="card-body" v-if="order">
              <div class="row">
                <div class="col-6">
                  <p class="h3">{{ order.company.name }}</p>
                  <address v-html="order.company.address"></address>
                </div>
                <div class="col-6 text-end">
                  <p class="h3">{{ order.shipping_addresses[0].company_name ? order.shipping_addresses[0].company_name :
                    order.shipping_addresses[0].recipient_name }}</p>
                  <address v-html="order.shipping_addresses[0].html_address"></address>
                </div>
                <div class="col-12 my-5">
                  <h1>Invoice # {{ order.full_id }}</h1>
                </div>
              </div>
              <table class="table table-transparent table-responsive">
                <thead>
                  <tr>
                    <th class="text-center" style="width: 1%"></th>
                    <th>Product</th>
                    <th class="text-center" style="width: 1%">Qnt</th>
                    <th class="text-end" style="width: 1%">Unit</th>
                    <th class="text-end" style="width: 1%">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, index) in order.items" :key="index">
                    <tr>
                      <td class="text-center">{{ index + 1 }}</td>
                      <td>
                        <p class="strong mb-1">{{ item.product }}</p>
                        <div class="text-muted">{{ item.product_sub_category }}</div>
                      </td>
                      <td class="text-center">
                        {{ item.quantity }}
                      </td>
                      <td class="text-end text-nowrap">{{ item.price_f }}</td>
                      <td class="text-end text-nowrap">{{ item.subtotal_f }}</td>
                    </tr>
                  </template>
                </tbody>

                <tfoot>
                  <tr v-if="order.tax">
                    <td colspan="4" class="strong text-end">Subtotal</td>
                    <td class="text-end text-nowrap">{{ order.total_before_tax_f }}</td>
                  </tr>
                  <tr v-if="order.tax">
                    <td colspan="4" class="strong text-end text-nowrap">{{ order.tax.type }} {{ order.tax.rate }}%</td>
                    <td class="text-end text-nowrap">{{ order.tax.amount_f }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="strong text-end">Total Invoiced</td>
                    <td class="font-weight-bold text-end text-nowrap">{{ order.total_f }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="strong text-end">Total Paid</td>
                    <td class="font-weight-bold text-end text-nowrap">{{ order.total_paid_f }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="strong text-uppercase text-end">Total Due</td>
                    <td class="font-weight-bold text-end text-nowrap">{{ order.total_owing_f }}</td>
                  </tr>
                </tfoot>

              </table>
              <p class="text-muted text-center mt-5">Thank you very much for doing business with us. We look forward to
                working with you again.</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>

export default {
  data() {
    return {
      order: null,
    }
  },
  async created() {
    try {
      const response = await this.$axios.get(this.$dist.endpoint('orders/' + this.$route.params.orderId))
      this.order = response.data.order
      console.log(response.data.order);
    } catch (error) {
      console.error(error)
    }
  }
}
</script>