<template>


  <div class="alert alert-danger" role="alert" v-if="Object.keys(errors).length > 0">
    <div class="d-flex gap-2">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="icon icon-tabler icons-tabler-outline icon-tabler-alert-circle">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
          <path d="M12 8v4" />
          <path d="M12 16h.01" />
        </svg>
      </div>
      <div>
        <h4 class="alert-title">Error - please correct the following errors and submit the form again.</h4>
        <div class="text-secondary">
          <ul>
            <li v-for="(error, field) in errors" :key="field">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="alert alert-success" role="alert" v-if="message">
    <div class="d-flex gap-2">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="icon icon-tabler icons-tabler-outline icon-tabler-check">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5 12l5 5l10 -10" />
        </svg>
      </div>
      <div>
        <h4 class="alert-title">Form Submitted</h4>
        <div class="text-secondary">
          {{ message }}
        </div>
      </div>
    </div>
  </div>

  <template v-else>

    <div class="page-header d-print-none">
      <div class="container-xl">
        <h2 class="page-title">
          <template v-if="isDropshipping">Step 2: Dropshipping address</template>
          <template v-else>Step 2: Shipping address</template>
        </h2>
      </div>
    </div>

    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards">
          <div class="col-12">

            <div class="card">

              <div class="card-header d-flex gap-3" v-if="!selectedShippingAddressId">
                <div v-if="shippingAddresses.length && showShippingAddressForm">
                  <span>Enter the address where the kit should be drop shipped to your client below, or</span>
                  <a href="#" @click.prevent="toggleShippingAddressForm(false)">
                    ship to distributor
                  </a>
                </div>
              </div>

              <div class="card-body">

                <template v-if="selectedShippingAddressId">
                  <p v-html="shippingAddresses.find(sa => sa.id === selectedShippingAddressId).html"></p>
                  <a href="#" v-if="selectedShippingAddressId" @click.prevent="unselectShippingAddressId">
                    Change
                  </a>
                </template>

                <template
                  v-else-if="!selectedShippingAddressId && shippingAddresses.length && !showShippingAddressForm">
                  <label class="form-label">Select shipping address:</label>
                  <div v-for="address in shippingAddresses" :key="address.id" class="form-check">
                    <input class="form-check-input" type="radio" name="selectedShippingAddress"
                      :id="'address-' + address.id" :value="address.id" v-model="selectedShippingAddressId">
                    <label class="form-check-label" :for="'address-' + address.id">
                      {{ address.text }}
                    </label>
                  </div>
                </template>

                <template v-else-if="showShippingAddressForm">
                  <ShippingAddressForm :show-submit-button="false" :show-set-default-checkbox="false"
                    @form-change="onShippingAddressFormChange">
                  </ShippingAddressForm>
                </template>

                <div v-if="!selectedShippingAddressId" class="d-flex gap-2 mt-4">
                  <a
                    v-if="!showShippingAddressForm"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-add-shipping-address"
                    @click="resetShippingFormFields"
                  >
                    Add a new distributor address
                  </a>

                  <span v-if="!selectedShippingAddressId && (!showShippingAddressForm || (showShippingAddressForm && !isDropshipping))">or</span>

                  <a href="#" v-if="!showShippingAddressForm || (showShippingAddressForm && !isDropshipping)"
                    @click.prevent="toggleShippingAddressForm(true)">
                    Dropship to customer
                  </a>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="page-header d-print-none">
      <div class="container-xl">
        <h2 class="page-title">
          Step 3: Review items and shipping
        </h2>
      </div>
    </div>

    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards">
          <div class="col-12">
            <div class="card">

              <div class="card-body">

                <template v-if="availableShippingRules.length">

                  <div class="col-md-12 col-xl-12 mb-5">

                    <label class="form-label">Select Shipping Method:</label>

                    <template v-for="rule in availableShippingRules" :key="rule.id">
                      <label class="form-check">
                        <input class="form-check-input" type="radio" name="shipping_method" :value="rule.id"
                          v-model="selectedShippingRuleId">
                        <span class="form-check-label">{{ rule.label }})</span>
                      </label>
                    </template>

                  </div>

                </template>


                <template v-if="orderExists">
                  <div class="mb-5">
                    <table class="table card-table table-vcenter">
                      <thead>
                        <tr>
                          <th style="width: 10%;">SKU</th>
                          <th style="width: 40%;">Product</th>
                          <th style="width: 20%;">Price</th>
                          <th style="width: 20%;">Unit</th>
                          <th style="width: 10%;">Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="product in orderedProducts" :key="product.product_offer_id">
                          <tr v-if="product.unitCount > 0 && product.caseCount > 0">
                            <td rowspan="2">{{ product.upc_code }}</td>
                            <td rowspan="2">{{ product.label }}</td>
                            <td>{{ formatCurrency(product.unit_price.discount ? product.unit_price.discount.value :
                              product.unit_price.value, region.currency.format) }}</td>
                            <td>Each</td>
                            <td>
                              <select v-model="product.unitCount" @change="updateQuantity(product, 'unit')"
                                class="form-select select-quantity">
                                <option value="delete">0</option>
                                <option v-for="n in 50" :key="'unit-' + n" :value="n">{{ n }}</option>
                              </select>
                            </td>
                          </tr>
                          <tr v-if="product.unitCount > 0 && product.caseCount > 0">
                            <td>{{ formatCurrency(product.case_price.discount ? product.case_price.discount.value :
                              product.case_price.value, region.currency.format) }}</td>
                            <td>Case of {{ product.case.num_kits }}</td>
                            <td>
                              <select v-if="product.caseCount > 0" v-model="product.caseCount"
                                @change="updateQuantity(product, 'case')" class="form-select select-quantity">
                                <option value="delete">0</option>
                                <option v-for="n in 50" :key="'case-' + n" :value="n">{{ n }}</option>
                              </select>
                            </td>
                          </tr>

                          <tr v-if="product.unitCount > 0 && product.caseCount == 0">
                            <td>{{ product.upc_code }}</td>
                            <td>{{ product.label }}</td>
                            <td>{{ formatCurrency(product.unit_price.discount ? product.unit_price.discount.value :
                              product.unit_price.value, region.currency.format) }}</td>
                            <td>Each</td>
                            <td>
                              <select v-model="product.unitCount" @change="updateQuantity(product, 'unit')"
                                class="form-select select-quantity">
                                <option value="delete">0</option>
                                <option v-for="n in 50" :key="'unit-' + n" :value="n">{{ n }}</option>
                              </select>
                            </td>
                          </tr>

                          <tr v-if="product.unitCount == 0 && product.caseCount > 0">
                            <td>{{ product.upc_code }}</td>
                            <td>{{ product.label }}</td>
                            <td>{{ formatCurrency(product.case_price.discount ? product.case_price.discount.value :
                              product.case_price.value, region.currency.format) }}</td>
                            <td>Case of {{ product.case.num_kits }}</td>
                            <td>
                              <select v-if="product.caseCount > 0" v-model="product.caseCount"
                                @change="updateQuantity(product, 'case')" class="form-select select-quantity">
                                <option value="delete">0</option>
                                <option v-for="n in 50" :key="'case-' + n" :value="n">{{ n }}</option>
                              </select>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <div class="col-md-12 col-xl-12 d-flex flex-column text-end gap-3 mb-2">

                    <div v-if="shippingLoading">
                      <span class="mr-4 fw-bold fs-3">Shipping: <span>Loading...</span></span>
                    </div>
                    <div v-else>
                      <span class="mr-4 fw-bold fs-3">Shipping: <span v-if="shippingRates.shipping_rule_label">{{
                          formatCurrency(shippingRates.price, region.currency.format) }}</span></span>
                    </div>

                    <div v-if="tax && shippingLoading">
                      <span class="mr-4 fw-bold fs-3">Tax: <span>Loading...</span></span>
                    </div>
                    <div v-if="tax && !shippingLoading">
                      <span class="mr-4 fw-bold fs-3">{{ tax.tax_caption }} ({{ tax.tax_percentage }}%): <span
                          v-if="shippingRates.shipping_rule_label">{{ formatCurrency(tax.tax_amount,
                          region.currency.format) }}</span></span>
                    </div>

                    <div>
                      <span class="mr-4 fw-bold fs-3">Total: <span>{{ formatCurrency(total, region.currency.format)
                          }}</span></span>
                    </div>

                  </div>

                </template>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-header d-print-none">
      <div class="container-xl">
        <h2 class="page-title">
          Step 4: Payment method
        </h2>
      </div>
    </div>

    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards">
          <div class="col-12">
            <div class="card">

              <div class="card-body">

                <div v-if="paymentMethods.length && !showPaymentMethodForm">
                  <div class="col-md-6 col-xl-6">
                    <div class="mb-3">

                      <!--
                      <select v-model="selectedPaymentMethodId" class="form-select">
                        <option value="">Select existing payment method</option>
                        <option v-for="option in paymentMethods" :key="option.id" :value="option.id">{{ option.text }}</option>
                      </select>
                      -->

                      <label class="form-label">Select payment method:</label>

                      <template v-for="option in paymentMethods" :key="option.id">
                        <label class="form-check">
                          <input class="form-check-input" type="radio" :value="option.id"
                            v-model="selectedPaymentMethodId">
                          <span class="form-check-label">{{ option.text }})</span>
                        </label>
                      </template>

                    </div>
                  </div>
                </div>

                <div v-if="showPaymentMethodForm">
                  <div class="col-md-6 col-xl-6">
                    <div class="mb-3">
                      <PaymentMethodForm ref="paymentForm" :show-submit-button="false"
                        :show-set-default-checkbox="paymentMethods.length > 0" :is-standalone="false"
                        @errors-changed="onPaymentFormError">
                      </PaymentMethodForm>
                    </div>
                  </div>
                </div>

                <div v-if="paymentMethods.length" class="d-flex gap-2 mt-4">
                  <a href="#" v-if="showPaymentMethodForm" @click.prevent="togglePaymentMethodForm">Existing payment
                    method</a>
                  <a href="#" v-else @click.prevent="togglePaymentMethodForm">Add new payment method</a>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-body">
      <div class="container-xl">
        <div class="col-md-12 col-xl-12 d-flex justify-content-end mb-5">
          <button class="btn btn-lg btn-primary" @click="onSubmit">Place Order</button>
        </div>
      </div>
    </div>

    <div class="modal modal-blur fade" id="modal-add-shipping-address" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Shipping Method</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body">

            <div class="alert alert-success" role="alert" v-if="modalMessage">
              <div class="d-flex gap-2">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l5 5l10 -10" /></svg>
                </div>
                <div>
                  <h4 class="alert-title">Form Submitted</h4>
                  <div class="text-secondary">
                    {{ modalMessage }}
                    <a href="#" data-bs-dismiss="modal" aria-label="Close">Go Back</a>
                  </div>
                </div>
              </div>
            </div>

            <ShippingAddressForm
              v-else
              :show-submit-button="false"
              :show-set-default-checkbox="false"
              :externalErrors="modalErrors"
              @form-change="onShippingAddressFormChange"
            />

          </div>

          <div class="modal-footer" v-if="!modalMessage">
            <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
              Cancel
            </a>
            <a href="#" class="btn btn-primary ms-auto" @click="onModalShippingAddressSubmit">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 5l0 14" /><path d="M5 12l14 0" /></svg>
              Save
            </a>
          </div>
        </div>
      </div>
    </div>

  </template>

</template>

<style scoped>
.select-quantity {
  min-width: 60px;
}
</style>

<script>
import { reactive } from 'vue';
import ShippingAddressForm from './ShippingAddressForm.vue';
import PaymentMethodForm from './PaymentMethodForm.vue';
export default {
  components: {
    ShippingAddressForm,
    PaymentMethodForm
  },
  data() {
    return {
      region: null,

      message: null,
      errors: reactive({}),

      modalMessage: null,
      modalErrors: reactive({}),

      tax: null,
      
      orderedProducts: [],
      
      shippingAddresses: [],
      selectedShippingAddressId: '',
      showShippingAddressForm: false,
      isDropshipping: false,
      shippingLoading: false,
      
      paymentMethods: [],
      selectedPaymentMethodId: null,
      showPaymentMethodForm: false,
      
      availableShippingRules: [],
      selectedShippingRuleId: null,
      shippingRates: {},
      
      shippingAddressInput: {
        firstName: null,
        lastName: null,
        companyName: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        stateId: null,
        countryId: null,
        zipCode: null,
        phone: null,
        comments: null
      },

      paymentMethodInput: {
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
        isDefault: false
      }
    };
  },
  computed: {
    orderExists() {
      return this.orderedProducts.length > 0;
    },
    totalBeforeTax() {
      return this.orderedProducts.reduce((acc, product) => acc + product.subtotal, 0) + (this.shippingRates.price ? this.shippingRates.price : 0);
    },
    total() {
      return this.totalBeforeTax + (this.tax ? this.tax.tax_amount : 0);
    },
    isCaseOrder() {
      //return this.orderedProducts.some(p => p.caseCount > 0);
      return true;
    }
  },
  async created() {
    const savedOrder = JSON.parse(localStorage.getItem('order'));
    if (savedOrder) {

      const regionResponse = await this.$axios.get(this.$dist.endpoint('region'));
      this.region = regionResponse.data.region;

      const response = await this.$axios.get(this.$dist.endpoint('products'));
      const allProducts = response.data.products;

      this.orderedProducts = allProducts.filter(product =>
        savedOrder.units[product.product_offer_id] || savedOrder.cases[product.product_offer_id]
      ).map(product => {
        const unitTotal = product.unit_price ? ((product.unit_price.discount ? product.unit_price.discount.value : product.unit_price.value) * (savedOrder.units[product.product_offer_id] || 0)) : 0;
        const caseTotal = product.case_price ? ((product.case_price.discount ? product.case_price.discount.value : product.case_price.value) * (savedOrder.cases[product.product_offer_id] || 0)) : 0;
        return {
          ...product,
          unitCount: savedOrder.units[product.product_offer_id] || 0,
          caseCount: savedOrder.cases[product.product_offer_id] || 0,
          subtotal: unitTotal + caseTotal
        };
      });

      try {
        const pmResponse = await this.$axios.get(this.$dist.endpoint('payment-methods'));
        this.paymentMethods = pmResponse.data.payment_methods;
        if (this.$route.params.mode === 'dropship') {
          this.toggleShippingAddressForm(true);
        }
        if (this.paymentMethods.length == 0) {
          this.showPaymentMethodForm = true;
        }
        else {
          this.selectedPaymentMethodId = this.paymentMethods[0].id;
        }
      } catch (error) {
        console.error(error);
      }

      try {
        const saResponse = await this.$axios.get(this.$dist.endpoint('shipping-addresses'));
        this.shippingAddresses = saResponse.data.shipping_addresses;
        if (this.shippingAddresses.length == 0) {
          this.showShippingAddressForm = true;
        }
        else if (!this.isDropshipping) {
          this.selectedShippingAddressId = this.shippingAddresses.find(sa => sa.is_default).id;
        }
      } catch (error) {
        console.error(error);
      }

      try {
        const srResponse = await this.$axios.get(this.$dist.endpoint('shipping-rules/select-options'));
        this.availableShippingRules = srResponse.data.shipping_rules;
        if (this.availableShippingRules.length > 0) {
          this.selectedShippingRuleId = this.availableShippingRules[0].id;
        }
      } catch (error) {
        console.error(error);
      }

    } else {
      this.$router.push('/' + this.$route.params.regionPrefix + '/' + this.$route.params.websiteId + '/dashboard');
    }
  },
  methods: {
    formatCurrency(value, formatString) {
      return formatString.replace('%f', new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value));
    },
    toggleShippingAddressForm(isDropshipping) {
      this.selectedShippingAddressId = null;
      this.showShippingAddressForm = !this.showShippingAddressForm;
      this.isDropshipping = isDropshipping;
      console.log('Dropshipping?', this.isDropshipping);
      if (!this.showShippingAddressForm && this.shippingAddresses.length > 0) {
        //this.selectedShippingAddressId = '';
        this.selectedShippingAddressId = this.shippingAddresses.find(sa => sa.is_default).id;
      }
    },
    togglePaymentMethodForm() {
      this.showPaymentMethodForm = !this.showPaymentMethodForm;
      if (this.showPaymentMethodForm) {
        this.selectedPaymentMethodId = null;
      }
      else if (this.paymentMethods.length > 0) {
        this.selectedPaymentMethodId = this.paymentMethods[0].id;
      }
    },
    unselectShippingAddressId() {
      this.selectedShippingAddressId = '';
    },
    onShippingAddressFormChange(payload) {
      this.$dist.assignS2C(this.shippingAddressInput, payload.formData);
    },
    onPaymentFormError(payload) {

      console.log('onPaymentFormError: ', payload);

      Object.keys(this.errors).forEach(key => {  
        if (key.startsWith('__paymentForm__')) {
          delete this.errors[key];
        }
      });
      Object.keys(payload.errors).forEach(key => {
        this.errors['__paymentForm__' + key] = payload.errors[key];
      });

      console.log('updated this.errors: ', this.errors);

    },
    async findRates() {
      if (this.selectedShippingRuleId && this.shippingAddressInput.countryId) {

        this.shippingLoading = true;

        let params = {
          shipping_rule_id: this.selectedShippingRuleId,
          order: localStorage.getItem('order'),
        };
        this.$dist.assignC2S(params, this.shippingAddressInput, true);
        console.log(params);
        try {
          const response = await this.$axios.get(this.$dist.endpoint('shipping-rules/rates'), {
            params: params
          });
          console.log(response.data);
          if (response.data.rates) {
            this.shippingRates = response.data.rates;
          }
          else {
            this.errors['error'] = 'Shipping rates not found.'
          }
          this.updateTax();

          this.shippingLoading = false;

        } catch (error) {
          console.error(error);
        }
      }
    },
    async updateTax() {
      if (this.shippingAddressInput.countryId == 1) {
        const taxResponse = await this.$axios.get(this.$dist.endpoint('states/' + this.shippingAddressInput.stateId + '/tax/' + this.totalBeforeTax));
        console.log(taxResponse);
        this.tax =taxResponse.data.tax;
      }
    },
    async onSubmit() {
      let params = {
        shipping_address: this.$dist.assignC2S({}, this.shippingAddressInput, true),
        shipping_address_id: this.selectedShippingAddressId,
        shipping_rule_id: this.selectedShippingRuleId,
        shipping_rates: this.shippingRates,
        is_dropshipping: this.isDropshipping ? 1 : 0,
        payment_method_id: this.selectedPaymentMethodId,
        order: localStorage.getItem('order'),
        comments: this.shippingAddressInput.comments,
        total: this.total
      };
      try {
        const vldResponse = await this.$axios.post(this.$dist.endpoint('orders/validate'), params);
        console.log(vldResponse.data);

        if (this.showPaymentMethodForm) {
          params.payment_method_id = await this.$refs.paymentForm.onSubmit();
          console.log(params);
        }

        if (Object.keys(this.errors).length > 0) {
          return;
        }
        
        try {
          const orderResponse = await this.$axios.post(this.$dist.endpoint('orders'), params);
          console.log(orderResponse.data);
          this.message = 'Your order has been successfully placed: ' + orderResponse.data.distributor_order.id;
          localStorage.removeItem('order');

        } catch (error) {
          this.errors = error.response.data.errors;
        }
        
      } catch (error) {
        this.errors = error.response.data.errors;
      }
    },
    updateQuantity(product, which) {

      let order = JSON.parse(localStorage.getItem('order'));
      let theOther = which === 'unit' ? 'case' : 'unit';
      let theOtherTotal = product[theOther + '_price'] ? ((product[theOther + '_price'].discount ? product[theOther + '_price'].discount.value : product[theOther + '_price'].value) * product[theOther + 'Count']) : 0;

      if (product[which + 'Count'] === 'delete') {
        const index = this.orderedProducts.findIndex(p => p.product_offer_id === product.product_offer_id);
        this.orderedProducts[index][which + 'Count'] = 0;
        this.orderedProducts[index][which + 'Total'] = 0;

        if (product[theOther + 'Count'] === 0) {
          this.orderedProducts.splice(index, 1);
        }
        else {
          this.orderedProducts[index].subtotal = theOtherTotal;
        }
        delete order[which + 's'][product.product_offer_id];

        if (this.orderedProducts.length === 0) {
          this.$router.push('/' + this.$route.params.regionPrefix + '/' + this.$route.params.websiteId + (this.isDropshipping ? '/dropship' : '/wholesale') + '/products');
        }

      } else {
        const whichTotal = product[which + '_price'] ? ((product[which + '_price'].discount ? product[which + '_price'].discount.value : product[which + '_price'].value) * product[which + 'Count']) : 0;
        product.subtotal = whichTotal + theOtherTotal;
        order[which + 's'][product.product_offer_id] = product[which + 'Count'];
      }

      localStorage.setItem('order', JSON.stringify(order));
      
      if (this.orderedProducts.length === 0) {
        this.$router.push('/' + this.$route.params.regionPrefix + '/' + this.$route.params.websiteId + '/products');
      }
      else {
        this.findRates();
      }
    },
    resetShippingFormFields() {
      this.shippingAddressInput = {
        firstName: null,
        lastName: null,
        companyName: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        stateId: null,
        countryId: null,
        zipCode: null,
        phone: null,
        comments: null
      };
    },
    async onModalShippingAddressSubmit() {

      try {
        const input = this.$dist.assignC2S({}, this.shippingAddressInput, true);
        const response = await this.$axios.post(this.$dist.endpoint('shipping-addresses'), input);
        console.log(response.data);

        this.modalMessage = 'Shipping address has been added successfully.';
        
        const saResponse = await this.$axios.get(this.$dist.endpoint('shipping-addresses'));
        this.shippingAddresses = saResponse.data.shipping_addresses;
        this.selectedShippingAddressId = this.shippingAddresses[this.shippingAddresses.length - 1].id;

      } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          this.modalErrors = error.response.data.errors;
        }
      }
    }
  },
  watch: {
    selectedShippingAddressId(newVal) {
      if (newVal) {
        const selectedAddress = this.shippingAddresses.find(sa => sa.id === newVal);
        if (selectedAddress) {
          console.log(selectedAddress);
          this.$dist.assignS2C(this.shippingAddressInput, selectedAddress);
        }
      }
    },
    shippingAddressInput: {
      deep: true,
      handler() {
        this.findRates();
      }
    },
    selectedShippingRuleId() {
      this.findRates();
    }
  }
}
</script>
