import { createRouter, createWebHistory } from 'vue-router'
import LoginForm from '@/components/LoginForm.vue'
import AppDashboard from '@/components/AppDashboard.vue'
import ProductCatalog from '@/components/ProductCatalog.vue'
import AccountMain from '@/components/AccountMain.vue'
import AccountForm from '@/components/AccountForm.vue'
import EmailForm from '@/components/EmailForm.vue'
import PasswordForm from '@/components/PasswordForm.vue'
import OrderForm from '@/components/OrderForm.vue'
import OrderList from '@/components/OrderList.vue'
import OrderInvoice from '@/components/OrderInvoice.vue'
import ShippingAddressList from '@/components/ShippingAddressList.vue'
import ShippingAddressAdd from '@/components/ShippingAddressAdd.vue'
import ShippingAddressEdit from '@/components/ShippingAddressEdit.vue'
import PaymentMethodList from '@/components/PaymentMethodList.vue'
import PaymentMethodAdd from '@/components/PaymentMethodAdd.vue'
import PasswordRequest from '@/components/PasswordRequest.vue'
import PasswordReset from '@/components/PasswordReset.vue'

const routes = [
  {
    path: '/:regionPrefix/:websiteId/login',
    name: 'login',
    component: LoginForm
  },
  {
    path: '/:regionPrefix/:websiteId/dashboard',
    name: 'dashboard',
    component: AppDashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/account',
    name: 'account',
    component: AccountMain,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/account/edit',
    name: 'account-edit',
    component: AccountForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/email/edit',
    name: 'email-edit',
    component: EmailForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/password/edit',
    name: 'password-edit',
    component: PasswordForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/:mode/products',
    name: 'products',
    component: ProductCatalog,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/:mode/checkout',
    name: 'checkout',
    component: OrderForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/orders',
    name: 'orders',
    component: OrderList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/orders/:orderId',
    name: 'orders-invoice',
    component: OrderInvoice,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/shipping-addresses',
    name: 'shipping-addresses',
    component: ShippingAddressList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/shipping-addresses/add',
    name: 'shipping-addresses-add',
    component: ShippingAddressAdd,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/shipping-addresses/:distributorShippingAddressId',
    name: 'shipping-addresses-edit',
    component: ShippingAddressEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/paymenth-methods',
    name: 'payment-methods',
    component: PaymentMethodList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/payment-methods/add',
    name: 'payment-methods-add',
    component: PaymentMethodAdd,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:regionPrefix/:websiteId/password/request',
    name: 'password-request',
    component: PasswordRequest
  },
  {
    path: '/:regionPrefix/:websiteId/password/reset/:code',
    name: 'password-reset',
    component: PasswordReset
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
