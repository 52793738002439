<template>
  <aside class="navbar navbar-vertical navbar-expand-lg z-index-5" data-bs-theme="light" style="z-index: 1000 !important">
    <div class="container-fluid">
      
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebar-menu" aria-controls="sidebar-menu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <h1 class="navbar-brand navbar-brand-autodark">
        <router-link :to="{ name: 'dashboard', params: $dist.rpwiParams() }">
          <img :src="$store.state.website.images.logo" v-if="$store.state.website.fqdn.naked" style="max-width: 240px; height: auto !important; max-height: 60px;" />
        </router-link>
      </h1>

      <div class="navbar-nav flex-row d-lg-none">
        <div class="nav-item dropdown">
          
          <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
            <div>{{ $store.state.user.business_name }}</div>
          </a>

          <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <router-link class="dropdown-item" :to="{ name: 'shipping-addresses', params: $dist.rpwiParams() }">Shipping Addresses</router-link>
            <router-link class="dropdown-item" :to="{ name: 'payment-methods', params: $dist.rpwiParams() }">Payment Methods</router-link>
            <router-link class="dropdown-item" :to="{ name: 'account', params: $dist.rpwiParams() }">Account</router-link>
            <div class="dropdown-divider"></div>
            <a href="#" @click="$dist.logout" class="dropdown-item">Logout</a>
          </div>

        </div>
      </div>
      
      <div class="collapse navbar-collapse" id="sidebar-menu">
        <ul class="navbar-nav pt-lg-3">
          
          <li class="nav-item" :class="{ 'active': $route.name === 'dashboard' }">
            <router-link class="nav-link" :to="{ name: 'dashboard', params: $dist.rpwiParams() }">
              <span class="nav-link-icon d-md-none d-lg-inline-block">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l-2 0l9 -9l9 9l-2 0" /><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /><path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
              </span>
              <span class="nav-link-title">
                Dashboard
              </span>
            </router-link>
          </li>

          <li class="nav-item" :class="{ 'active': $route.name === 'orders' }">
            <router-link class="nav-link" :to="{ name: 'orders', params: $dist.rpwiParams() }">
              <span class="nav-link-icon d-md-none d-lg-inline-block">
                <svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-list"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l11 0" /><path d="M9 12l11 0" /><path d="M9 18l11 0" /><path d="M5 6l0 .01" /><path d="M5 12l0 .01" /><path d="M5 18l0 .01" /></svg>
              </span>
              <span class="nav-link-title">
                Order History
              </span>
            </router-link>
          </li>

        </ul>
      </div>

    </div>
  </aside>
</template>

<style>
.navbar-brand img {
  height: 60px;
}
@media (min-width: 992px) {
  .navbar-brand img {
    height: 75px;
  }
}
.dropdown-divider {
  margin: 5px 0;
}
</style>
