<template>
  <header class="navbar navbar-expand-md d-lg-flex d-print-none" >
    <div class="container-xl">
      
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu" aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <div class="navbar-nav flex-row order-md-last">
        <div class="d-md-flex"></div>
        <div class="nav-item dropdown me-2">
          <a :href="'mailto:distributor@' + $store.state.website.fqdn.naked" class="nav-link d-flex lh-1 text-reset p-0">Contact Support</a>
        </div>
        <div class="nav-item dropdown">
          <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
            <svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-user me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
            {{ $store.state.user.business_name }}
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <router-link class="dropdown-item" :to="{ name: 'shipping-addresses', params: $dist.rpwiParams() }">Shipping Addresses</router-link>
            <router-link class="dropdown-item" :to="{ name: 'payment-methods', params: $dist.rpwiParams() }">Payment Methods</router-link>
            <router-link class="dropdown-item" :to="{ name: 'account', params: $dist.rpwiParams() }">Account</router-link>
            <div class="dropdown-divider"></div>
            <a href="#" @click="$dist.logout" class="dropdown-item">Logout</a>
          </div>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="navbar-menu">
      </div>
    </div>
  </header>
</template>