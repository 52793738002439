<template>
  <div class="flex-container">
    <img src="../assets/dropship.png" />
    <div class="mt-4">
      <h2>Dropship directly to your clients</h2>
      <p>Offer DNA test kits to your customers without the hassle of inventory management. Just select the products, provide shipping details, and we will ship the kit directly to your client. </p>
      <p class="mt-2">
        <router-link
          :to="{ name: 'products', params: $dist.rpwiParams({ mode: 'dropship' }) }"
          class="btn btn-primary"
        >
          Order Dropship
        </router-link>
      </p>
    </div>
  </div>

  <div class="flex-container">
    <img src="../assets/wholesale.png" />
    <div class="mt-3">
      <h2>Purchase wholesale</h2>
      <p>Order DNA test kits at wholesale pricing. Stock up your inventory and take control of your own sales. Plus, consider our retail display options to showcase your kits for maximum visibility and sales. </p>
      <p class="mt-2">
        <router-link
          :to="{ name: 'products', params: $dist.rpwiParams({ mode: 'wholesale' }) }"
          class="btn btn-primary"
        >
          Order Wholesale
        </router-link>
      </p>
    </div>
  </div>
</template>

<style scoped>
.flex-container {
    display: flex;
    align-items: center;
}

.flex-container img {
    width: 60px;
    margin-right: 15px;
}

</style>