<template>
  
  <div class="page-header d-print-none">
    <div class="container-xl">
      <h2 class="page-title">
        Account
      </h2>
    </div>
  </div>

  <div class="page-body">
    <div class="container-xl">
      <div class="row row-cards">
        
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">
                Account Information
              </h4>
            </div>
            <div class="card-body">
              <AccountForm :initial-state="initialState" v-if="initialState" />
            </div>
          </div>
        </div>
        
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">
                Edit Email
              </h4>
            </div>
            <div class="card-body">
              <EmailForm />
            </div>
          </div>
        </div>
        
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">
                Edit Password
              </h4>
            </div>
            <div class="card-body">
              <PasswordForm />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import AccountForm from './AccountForm.vue';
import EmailForm from './EmailForm.vue';
import PasswordForm from './PasswordForm.vue';
export default {
  components: {
    AccountForm,
    EmailForm,
    PasswordForm
  },
  data() {
    return {
      initialState: null
    }
  },
  async created() {
    try {
      const accountResponse = await this.$axios.get(this.$dist.endpoint('account'));
      this.initialState = accountResponse.data.account;
    } catch (error) {
      console.error(error);
    }
  }
}
</script>
